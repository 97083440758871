import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import RequestService from "../../services/RequestService";

export const initialState = {
    loading: false,
    list: [],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0
    },
    details: {}
}

/* eslint-disable-next-line no-unused-vars */
export const fetchBookings = createAsyncThunk('bookings/fetch',async ({current = 1, pageSize = 10, clientId = null}, { rejectWithValue }) => {
    try {
        return await RequestService.get(`/sales/bookings/?page=${current}${clientId ? `&client=${clientId}`: ''}`);
    } catch (err) {
        console.error(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        setBookingDetails: (state, action) => {
            const bookingDetails = state.list.filter(booking => booking.id === action.payload);
            state.details = bookingDetails.length ? bookingDetails[0] : null;
        },
    },
    extraReducers:(builder) => {
        builder
            .addCase(fetchBookings.pending, (state, action) => {
                state.loading = true
                state.list = []
                state.pagination = action.payload
            })
            .addCase(fetchBookings.fulfilled, (state, action) => {
                state.loading = false
                state.list = action.payload.results
                state.pagination = {...state.pagination, total:action.payload.count}
            })
            .addCase(fetchBookings.rejected, (state) => {
                state.loading = false
                state.list = []
            })
    }
});


export const {setBookingDetails} = bookingSlice.actions

export default bookingSlice.reducer
