import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import RequestService from "../../services/RequestService";

export const initialState = {
    loading: false,
    list: [],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0
    },
    details: {}
}

export const fetchVendors = createAsyncThunk('enums/fetch',async ({current /*, pageSize*/}, { rejectWithValue }) => {
    try {
        return await RequestService.get(`/asset-management/vendors/?page=${current}`);
    } catch (err) {
        console.error(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const vendorSlice = createSlice({
    name: 'vendors',
    initialState,
    reducers: {
        setVendorDetails: (state, action) => {
            const vendorDetails = state.list.filter(vendor => vendor.id === action.payload);
            state.details = vendorDetails.length ? vendorDetails[0] : null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVendors.pending, (state, action) => {
                state.loading = true
                state.list = []
                state.pagination = action.payload
            })
            .addCase(fetchVendors.fulfilled, (state, action) => {
                state.loading = false
                state.list = action.payload.results
                state.pagination = {...state.pagination, total:action.payload.count}
            })
            .addCase(fetchVendors.rejected, (state) => {
                state.loading = false
                state.list = []
            })
    }
});

export const {setVendorDetails} = vendorSlice.actions

export default vendorSlice.reducer;