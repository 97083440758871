import {combineReducers} from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import enums from './slices/enumSlice';
import partners from "./slices/partnerSlice";
import clients from "./slices/clientSlice";
import vendors from "./slices/vendorSlice";
import bookings from "./slices/bookingSlice";
import screens from "./slices/screenSlice";
import campaigns from "./slices/campaignSlice";

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        enums,
        partners,
        clients,
        vendors,
        bookings,
        screens,
        campaigns,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
