const storageVersion = '_1';

export default class Storage {
    /**
     * Get item by name from storage.
     *
     * @param keyName
     * @param defaultValue
     * @returns {any|null}
     */
    static getItem = (keyName: string, defaultValue = null) => {
        const item = localStorage.getItem(keyName + storageVersion);

        return item ? JSON.parse(item) : defaultValue;
    }

    /**
     * Check if item exists by name in storage.
     *
     * @param keyName
     * @returns {boolean}
     */
    static hasItem = (keyName: string) => {
        return !!localStorage.getItem(keyName + storageVersion);
    }

    /**
     * Store item into storage.
     *
     * @param keyName
     * @param keyValue
     */
    static setItem = (keyName: string, keyValue) => {
        localStorage.setItem(keyName + storageVersion, JSON.stringify(keyValue));
    }

    static removeItem = (keyName: string) => {
        localStorage.removeItem(keyName + storageVersion);
    }
}