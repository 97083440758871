import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import RequestService from "../../services/RequestService";

export const initialState = {
    loading: false,
    data: {}
}

export const fetchEnums = createAsyncThunk('enums/fetch',async (_, { rejectWithValue }) => {
    try {
        return await RequestService.get('/inventory/ad-inventory-enums/');
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const enumSlice = createSlice({
    name: 'enum',
    initialState,
    reducers:{},
    extraReducers:(builder) => {
        builder
            .addCase(fetchEnums.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchEnums.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload
            })
            .addCase(fetchEnums.rejected, (state) => {
                state.loading = false
                state.data = {}
            })
    }
});

export default enumSlice.reducer