import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import RequestService from "../../services/RequestService";

export const initialState = {
    loading: false,
    list: [],
    pagination: {
        page: 1,
        page_size: 10,
        total: 0
    },
    details: {}
}

/* eslint-disable-next-line no-unused-vars */
export const fetchScreensOld = createAsyncThunk(
    'screens/fetch',
    async ({page = 1, page_size = 10, live_status = null}, {rejectWithValue}) => {
        try {
            return await RequestService.get(`/inventory/ad-inventories/?page=${page}&page_size=${page_size}${!!live_status ? `&live_status=${live_status}` : ''}`);
        } catch (err) {
            console.error(err)
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const fetchScreens = createAsyncThunk(
    'screens/fetch',
    async (payload, {rejectWithValue}) => {
        try {
            const urlPayload = Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&');
            return await RequestService.get(`/inventory/ad-inventories/?${urlPayload}`);
        } catch (err) {
            console.error(err)
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {
        setScreenDetails: (state, action) => {
            const screenDetails = state.list.filter(screen => screen.id === action.payload);
            state.details = screenDetails.length ? screenDetails[0] : null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchScreens.pending, (state, action) => {
                state.loading = true
                state.list = []
                state.pagination = action.payload
            })
            .addCase(fetchScreens.fulfilled, (state, action) => {
                state.loading = false
                state.list = action.payload.results
                state.pagination = {...state.pagination, total: action.payload.count}
            })
            .addCase(fetchScreens.rejected, (state) => {
                state.loading = false
                state.list = []
            })
    }
});

export const {setScreenDetails} = screenSlice.actions

export default screenSlice.reducer