import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import RequestService from "../../services/RequestService";

export const initialState = {
    loading: false,
    list: [],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0
    },
    details: {},
    enums: null
}

/* eslint-disable-next-line no-unused-vars */
export const fetchCampaigns = createAsyncThunk(
    'campaigns/fetch', async (payload, {rejectWithValue}) => {
        try {
            const urlPayload = Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&');
            return await RequestService.get(`/player/campaigns/?${urlPayload}`);
        } catch (err) {
            console.error(err)
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    })

export const fetchCampaignEnums = createAsyncThunk('campaign_enums/fetch', async (data, {rejectWithValue}) => {
    try {
        return await RequestService.get(`/player/campaign-enums/`);
    } catch (err) {
        console.error(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        setCampaignDetails: (state, action) => {
            const campaignDetails = state.list.filter(campaign => campaign.id === action.payload);
            state.details = campaignDetails.length ? campaignDetails[0] : null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCampaigns.pending, (state, action) => {
                state.loading = true
                state.list = []
                state.pagination = action.payload
            })
            .addCase(fetchCampaigns.fulfilled, (state, action) => {
                state.loading = false
                state.list = action.payload.results
                state.pagination = {...state.pagination, total: action.payload.count}
            })
            .addCase(fetchCampaigns.rejected, (state) => {
                state.loading = false
                state.list = []
            })
            .addCase(fetchCampaignEnums.fulfilled, (state, action) => {
                state.enums = action.payload
            })
    }
});


export const {setCampaignDetails} = campaignSlice.actions

export default campaignSlice.reducer
