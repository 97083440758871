import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom'
import {ThemeSwitcherProvider} from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import {ENABLE_API_MOCKING, THEME_CONFIG} from './configs/AppConfig';
import './lang'
import mockServer from './mock'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween'

import "keen-slider/keen-slider.min.css";

dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const environment = process.env.NODE_ENV

if (environment !== 'production' && ENABLE_API_MOCKING) {
    mockServer({environment})
}

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter history={history}>
                    <ThemeSwitcherProvider
                        themeMap={themes}
                        defaultTheme={THEME_CONFIG.currentTheme}
                        insertionPoint="styles-insertion-point"
                    >
                        <Layouts/>
                    </ThemeSwitcherProvider>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

export default App;
