import React from 'react'
import {Navigate, Outlet, useSearchParams} from 'react-router-dom'
import {useSelector} from 'react-redux';
import {AUTHENTICATED_ENTRY} from 'configs/AppConfig'

const PublicRoute = () => {

    const [searchParams] = useSearchParams();

    const redirectTo = searchParams.has('redirect') && searchParams.get('redirect') ? searchParams.get('redirect') : AUTHENTICATED_ENTRY;

    const {token} = useSelector(state => state?.auth)

    return token ? <Navigate to={redirectTo}/> : <Outlet/>
}

export default PublicRoute