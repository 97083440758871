import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AUTH_TOKEN, ORG_OBJECT, USER_OBJECT} from 'constants/AuthConstant';
import AuthService from 'services/AuthService';
import {fetchEnums} from "./enumSlice";
import Storage from "../../utils/storage";

type Company = {
    list: Array,
    name: string,
    org_id: number,
    type: string
}

type User = {
    name: string
}

type AuthState = {
    loading: boolean,
    message: string | null,
    showMessage: boolean,
    redirect: string | null,
    token: string | null,
    user: User | null,
    company: Company | null
}

export const initialState: AuthState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: Storage.getItem(AUTH_TOKEN),
    user: Storage.getItem(USER_OBJECT),
    company: Storage.getItem(ORG_OBJECT),
}

export const signIn = createAsyncThunk('auth/login', async (data, {dispatch, rejectWithValue}) => {
    const {username, password} = data
    try {
        const response = await AuthService.login({username, password})
        Storage.setItem(AUTH_TOKEN, response.token);
        Storage.setItem(USER_OBJECT, response.user);
        Storage.setItem(ORG_OBJECT, response.org_info);
        dispatch(fetchEnums());
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signUp = createAsyncThunk('auth/register', async (data, {rejectWithValue}) => {
    const {email, password} = data
    try {
        const response = await AuthService.register({email, password})
        const token = response.data.token;
        Storage.setItem(AUTH_TOKEN, token);
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signOut = createAsyncThunk('auth/logout', async () => {
    // const response = await FirebaseService.signOutRequest()
    Storage.removeItem(AUTH_TOKEN);
    return true
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, {rejectWithValue}) => {
    try {
        const response = await AuthService.loginInOAuth()
        const token = response.data.token;
        Storage.setItem(AUTH_TOKEN, token);
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, {rejectWithValue}) => {
    try {
        const response = await AuthService.loginInOAuth()
        const token = response.data.token;
        Storage.setItem(AUTH_TOKEN, token);
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const changeSelectedCompany = createAsyncThunk('auth/orgChange', async (data, thunkAPI) => {
    const {name, org_id} = data
    try {
        await AuthService.changeCompany(org_id);
        return {name, org_id};
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticated: (state: AuthState, action) => {
            state.loading = false
            state.redirect = '/'
            state.token = action.payload
        },
        showAuthMessage: (state: AuthState, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        },
        hideAuthMessage: (state: AuthState) => {
            state.message = ''
            state.showMessage = false
        },
        signOutSuccess: (state: AuthState) => {
            state.loading = false
            state.token = null
            state.redirect = '/'
        },
        showLoading: (state: AuthState) => {
            state.loading = true
        },
        signInSuccess: (state: AuthState, action) => {
            state.loading = false
            state.token = action.payload
        },
        /*changeSelectedCompany: (state: AuthState, action) => {
            state.company.name = action.payload.name;
            state.company.org_id = action.payload.org_id;
            state.redirect = '/app'
            Storage.setItem(ORG_OBJECT, state.company)
        }*/
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state: AuthState) => {
                state.loading = true
            })
            .addCase(signIn.fulfilled, (state: AuthState, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload.token
                state.user = action.payload.user
                state.company = action.payload.org_info
            })
            .addCase(signIn.rejected, (state: AuthState, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
                state.token = null
                state.user = null
            })
            .addCase(signOut.fulfilled, (state: AuthState) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
            })
            .addCase(signOut.rejected, (state: AuthState) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
            })
            .addCase(signUp.pending, (state: AuthState) => {
                state.loading = true
            })
            .addCase(signUp.fulfilled, (state: AuthState, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signUp.rejected, (state: AuthState, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signInWithGoogle.pending, (state: AuthState) => {
                state.loading = true
            })
            .addCase(signInWithGoogle.fulfilled, (state: AuthState, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signInWithGoogle.rejected, (state: AuthState, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signInWithFacebook.pending, (state: AuthState) => {
                state.loading = true
            })
            .addCase(signInWithFacebook.fulfilled, (state: AuthState, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signInWithFacebook.rejected, (state: AuthState, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(changeSelectedCompany.fulfilled, (state: AuthState, action) => {
                state.company.name = action.payload.name;
                state.company.org_id = action.payload.org_id;
                state.redirect = '/app'
                Storage.setItem(ORG_OBJECT, state.company)
            })
    },
})

export const {
    // authenticated,
    showAuthMessage,
    hideAuthMessage,
    signOutSuccess,
    showLoading,
    // signInSuccess,
    // changeSelectedCompany
} = authSlice.actions

export default authSlice.reducer