import {SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR} from 'constants/ThemeConstant';
import {ALL_APP_MODES} from "./Constants";

export const APP_NAME = process.env.REACT_APP_MODE?.toLowerCase() === ALL_APP_MODES.SIGNAGE ? 'Onion Signage' : process.env.REACT_APP_MODE;
export const APP_ENV = process.env.REACT_APP_ENV;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboard`;
export const UNAUTHENTICATED_ENTRY = '/login'
export const ENABLE_API_MOCKING = false;//process.env.REACT_APP_ENABLE_API_MOCKING;
export const MEDIA_UPLOAD_API_URL = API_BASE_URL + '/common/media-upload/'
export const CURRENT_APP_MODE = process.env.REACT_APP_MODE?.toLowerCase();

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR,
    blankLayout: false
};
