export const DAY_OF_WEEK_OPTIONS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const TIME_OF_DAY_OPTIONS = ['6am - 10am', '10am - 4pm', '4pm - 12.30am'];

export const MEDIA_INITIAL_VALUES = {
    display_content_type: 'Image',
    player_art_work_id: 0,
    play_seconds: 10,
    video_seconds: 0,
    url: null,
    previewUrl: null,
    scheduleOptions: [],
    media_url: null,
    play_ratio: null
}

export const MAX_ARTWORKS_ALLOWED = {
    single: 1,
    round_robin: 5,
    slot_ratio: 3,
    day_of_week: DAY_OF_WEEK_OPTIONS.length,
    time_of_day: TIME_OF_DAY_OPTIONS.length
};

export const MEDIA_TYPE_OPTIONS = {
    Image: 'Image',
    Video: 'Video',
    Widget_Time: 'Time Widget',
    Widget_Weather: 'Weather Widget',
    Webview: 'Webview'
};

export const MEDIA_MAX_PLAY_SECONDS = 1800;

export const MOMENT_DATE_FORMAT_INPUT = 'YYYY-MM-DD';

export const MOMENT_DATE_TIME_FORMAT_INPUT = 'YYYY-MM-DDTHH:mm:ss[Z]'

export const ALL_APP_MODES = {
    DOOHUP: 'doohup',
    SIGNAGE: 'signage'
}