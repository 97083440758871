import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import RequestService from "../../services/RequestService";

export const initialState = {
    loading: false,
    list: [],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0
    },
    details: {}
}

/* eslint-disable-next-line no-unused-vars */
export const fetchPartners = createAsyncThunk(
    'partners/fetch',
    async (payload, {rejectWithValue}) => {
        try {
            const urlPayload = Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&');
            return await RequestService.get(`/inventory/partners/?${urlPayload}`);
        } catch (err) {
            console.error(err)
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
)

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        setPartnerDetails: (state, action) => {
            const partnerDetails = state.list.filter(partner => partner.id === action.payload);
            state.details = partnerDetails.length ? partnerDetails[0] : null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPartners.pending, (state, action) => {
                state.loading = true
                state.list = []
                state.pagination = action.payload
            })
            .addCase(fetchPartners.fulfilled, (state, action) => {
                state.loading = false
                state.list = action.payload.results
                state.pagination = {...state.pagination, total: action.payload.count}
            })
            .addCase(fetchPartners.rejected, (state) => {
                state.loading = false
                state.list = []
            })
    }
});

export const {setPartnerDetails} = partnerSlice.actions

export default partnerSlice.reducer
