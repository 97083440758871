import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import RequestService from "../../services/RequestService";

export const initialState = {
    loading: false,
    list: [],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0
    },
    details: {}
}

export const fetchClients = createAsyncThunk('enums/fetch',async ({current = 1/*, pageSize = 10*/}, { rejectWithValue }) => {
    try {
        return await RequestService.get(`/sales/clients/?page=${current}`);
    } catch (err) {
        console.error(err)
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers:{
        setClientDetails: (state, action) => {
            // todo: list is being filtered on the basis of client name
            const clientDetails = state.list.filter(client => client.name === action.payload);
            state.details = clientDetails.length ? clientDetails[0] : null;
        },
    },
    extraReducers:(builder) => {
        builder
            .addCase(fetchClients.pending, (state, action) => {
                state.loading = true
                state.list = []
                state.pagination = action.payload
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.loading = false
                state.list = action.payload.results
                state.pagination = {...state.pagination, total:action.payload.count}
            })
            .addCase(fetchClients.rejected, (state) => {
                state.loading = false
                state.list = []
            })
    }
});

export const {setClientDetails} = clientSlice.actions

export default clientSlice.reducer;
